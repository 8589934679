.section-scheduling {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;
  background-color: #1b63f9;
}

.card-scheduling {
  background-color: white;
  width: 95%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
  border-radius: 8px;
}

.a-button-pages {
  background-color: #1cbe1c;
  color: white;
  padding: 10px 15px;
}

@media (max-width: 750px) {
  .div-top-btns {
    margin-top: 20px;
    width: 100% !important;
    display: flex;
    justify-content: flex-end;
  }
}
