.report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(240, 240, 240);
  min-height: 100vh;
}

.card-container {
  width: 90%;
  margin-top: 20px;
  border: none;
}

.card-title {
  margin-bottom: 30px;
}

.export-and-search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.search-input {
  width: 50%;
  border-radius: 20px;
  background-color: rgb(240, 243, 249);
  border: none;
}

.pagination-btns-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 0.7rem;
}

.pagination-btn:disabled {
  color: #ced4da;
}

.pagination-btn {
  background-color: #ffffff;
  color: #74788d;
  border: 1px solid #ced4da;
  width: 32px;
  height: 32px;
  margin-right: -1px;
}

.pagination-dropdown {
  color: white;
  margin-right: 5px;
  font-size: 0.8125rem;
  border: none;
}

.page-number {
  color: #74788d;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  width: 32px;
  height: 32px;
  margin-right: -1px;
}

.page-number.active {
  color: #ffffff;
  background-color: #556ee6;
  border: #556ee6;
}

.icon-delete {
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 17px;
  padding: 5px;
  cursor: pointer;
}
