.section-diversa {
  width: 100%;
  min-height: 100vh;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;
  /* background-color: #2ba1c0; */
  background-color: #00417a;
}

.card-diversa {
  width: 90%;
  
}

.div-items-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.div-card-item {
  box-shadow: rgb(0, 0, 0, 0.25) 0px 10px 10px -5px;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-save-list {
  background-color: green;
  color: white;
  font-size: 30px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-delete-list {
  background-color: red;
  color: white;
  font-size: 22px;
  border-radius: 50%;
  width: 39px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.div-data-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  width: 100%;
}
