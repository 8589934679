.main-container {
  padding: 16px;
}
.main-container header {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}
.main-container section {
  margin-top: 16px;
}
.card-schedule {
  text-align: center;
  border-radius: 20px;
  border: 0.2px solid #a09f9f;
  padding: 10px;
  margin: 5% 5%;
}

.card-schedule-icon {
  margin-top: 5%;
  font-size: 3rem;
  color: cornflowerblue;
  margin-bottom: 4px;
}

.card-schedule p {
  font-size: 1rem;
}

.card-schedule-button {
  background-color: green;
  width: auto;
  padding: 14px 0 14px 0;
  border-radius: 20px;
  margin: 0 5% 5%;
}
.card-schedule-button p {
  margin:0;
  font-size: 1.1rem;
  color: #fff;
}



.card-schedule-disabled {
  text-align: center;
  border-radius: 20px;
  border: 0.2px solid #a09f9f;
  padding: 10px;
  margin: 5% 5%;
}

.card-schedule-icon-disabled {
  margin-top: 5%;
  font-size: 3rem;
  color: lightgray;
  margin-bottom: 4px;
}

.card-schedule-disabled p {
  font-size: 1rem;
  color: gray
}

.card-schedule-button-disabled {
  background-color: lightgray !important;
  width: auto;
  padding: 2px 0 2px 0;
  border-radius: 20px;
  margin: 0 5% 5%;
  text-align: center;
}
.card-schedule-button-disabled p {
  margin-top: 12px;
  font-size: 1.1rem;
  color: gray;
}


.card-activity {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 0.2px solid #a09f9f;
  /* padding: 15px; */
  margin: 5% 5%;
}

.card-activity p {
  font-size: 1.1rem;
  margin-top: 15px;
  color: dimgray;
  font-weight: 600;
}
.card-activity-icon {
  /* margin-top: 5%; */
  font-size: 1rem;
  color: cornflowerblue;
  margin-bottom: 4px;
}

.side-drawer {
  position: fixed;
  height: 100%;
  background: #fff;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 200;
  /* box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5); */
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}
.side-drawer.open {
  transform: translateX(0);
}

.card-time-activity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 0.2px solid #a09f9f;
  /* padding: 15px; */
  margin: 5% 5%;
}
.card-time-activity p:first-child {
  font-size: 1.1rem;
  margin-top: 15px;
  color: dimgray;
  font-weight: 600;
}

.side-drawer-time-activity {
  /* position: fixed; */
  height: 100%;
  background: #fff;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 200;
  /* box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5); */
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.side-drawer-time-activity.open {
  transform: translateX(0);
}

.screen-blocker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* semi-transparent black */
  z-index: 1; /* ensure it appears above other content */
}

.modal-confirmation-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  text-align: center;
  font-size: 1.3rem;
  margin-top: 25vh;
  margin-bottom: 50%;
  margin-left: 5%;
  margin-right: 5%;
  padding: 1rem;
  border-radius: 20px;
  border: 1px solid lightgray;
  box-shadow: 2px 2px 4px rgba(0 0, 0, 0.3);
}
.modal-confirmation-container p{
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
}

.modal-confirmation-container-subtitle{
 padding: 0px;
 margin: 0px;
 
}

.modal-confirmation-container-subtitle p{
  margin: 0;
  font-weight: normal;
  
}

.modal-confirmation-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.modal-confirmation-button {
  border-radius: 20px;
  box-Shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  width: 40%;
  padding: 10px;
}
.button-color-green {
  background-color: green;
  color: white;
}
.button-color-lightgray {
  margin-left: 5px;
  background-color: lightgray;
}

.modal-message-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}
.modal-message-button-container p{
  font-weight: normal;
}
.icon-exclamation {
  color: red;
  font-size: 60px;
}

.icon-check{
  color: green;
  font-size: 60px;
}
.container-modal-spinner{
  background-color: transparent
}
.spinner-in-modal {
    width: 5rem;
    height: 5rem;
    position: fixed;
    top: 30%;
    left: 35%;
}

.spinner-in-list {
  width: 5rem;
  height: 5rem;
  margin-top: 30vh;
  margin-left: 20vh;
}

.text-loading-card-list {
  width: 5rem;
  height: 5rem;
 
  text-align: center;
}

.waiting-for-card1 {
  animation: blinker 1.7s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}
@keyframes blinker { to { opacity: 0; } }

.waiting-for-card2 {
  animation: blinker2 0.6s cubic-bezier(1, 0, 0, 1) infinite alternate;  
}
@keyframes blinker2 { to { opacity: 0; } }

.waiting-for-card3 {
  animation: blinker3 1s ease-in-out infinite alternate;  
}
@keyframes blinker3 { to { opacity: 0; } }


.card-appointed p{
  margin: 0;
}
.rules-text {
  font-size: 1rem;
}

.rules-text-body p {
  margin:0;
}
.rules-text-footer-title {
  margin-top: 8px;
  margin-bottom: 6px;
  font-weight: 'bold';
}

.personalization-section {
  margin: 15px 10px 0px 0px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  justify-content: space-between; 
  width: 100%;
}

.search-filters {
  padding: 10px;
  margin-left: 5px;
  border: 1px solid #C8C8C8;
  border-radius: 10px;
}
.search-filters span{
  display: flex;
  flex-direction: row;
}
.search-item {
  width: auto;
  max-width: 140px;
}
.search-filters input{
  margin-right: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .card-schedule {
    margin: 5% 10%;
  }
  .card-schedule-icon {
    font-size: 6rem;
  }
  .card-schedule p {
    font-size: 1.5rem;
  }
}
