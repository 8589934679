.section-residentesache {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0;
    background-color: #D9166E;
  }
  
  .card-residentesache {
    background-color: white;
    width: 95%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
    border-radius: 8px;
  }
  
  .item-residentesache {
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
    margin: 10px 0;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .item-date-residentesache {
    border-right: 1px solid gray;
    padding-right: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    color: #D9166E;
    font-weight: 600;
  }
  
  .item-body-residentesache {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 10px;
  }
  
  .a-button-pages {
    background-color: #1cbe1c;
    color: white;
    padding: 10px 15px;
  }
  