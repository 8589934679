.el-main-container {
  text-align: center;
  padding: 16px;
  background-color: black;
  background-image: url("../../assets/image/encontro-lideres/bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: auto; 
  min-height: 100vh;
  background-size: cover;
}

.el-main-container img{
  transform: unset !important;
  width: 60%;
}

.el-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25%;
}
.el-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.el-description-title {
  font-size: 1.150rem ;
  color: white;
  /* margin-top: 1%; */
  font-weight: 600;

}
.el-description p {
  width: 50%;
  font-size: 1.125rem;
  /* margin-top: 1%; */
  color: white;
}
.el-p-uppercase {
  margin-bottom: 20px;
  font-size: 0.9rem !important;
}

.el-description-legal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.el-description-legal p {
  margin-top: 1%;
  width: 50%;
  font-size: 0.8rem;
  color: white;
}
.el-section {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1%;
  height: auto;
}
.el-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 100%;
  border-radius: 10%;
}
.el-card-container:hover {
  cursor: pointer;
}

.el-card-container img {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 10%;
  border: 0 transparent;
}

.el-card-container-edit-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 18%;
  border-radius: 10%;
  border: 0.2px solid gray;
  background-color: gray;
}
.el-card-container-edit-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: auto;
  border-radius: 10%;
  color: white;
  margin-top: 10%; 
}

.el-card-container-edit-text p{
  margin: 0;
}
.el-card-container-edit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 30%;
  border-radius: 10%;
}

.el-card-container-draft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 15%;
  height: 70%;
  border-radius: 10%;
}
.el-card-edit-button {
  height: auto;
  width: 30%;
  font-size: 0.7rem;
  margin-top: 2%;
  margin-bottom: 2%;
}

.el-warning-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4%;
}
.el-button-schedule {
  margin-top: 2%;
  margin-bottom: 1%;
}

.el-button-schedule p {
  margin-top: 0.5%;
  color: #FFF;
  font-size: 1rem;
}
 @media (min-width: 1920px) {
  .el-description p {
    font-size: 1.5rem;
  }
  .el-p-uppercase {
    font-size: 1.2rem !important;
  }
  .el-card-container {
    height: 60%;
    border-radius: 10%;
    
  }
} 

@media (max-width: 1024px) {
  .el-main-container {
    height: auto; 
  }
  .el-main-container img{
    width: 60%;
  }
  .el-header {
    height: auto;
  }
  .el-description p {
    width: 80%;
    font-size: 0.7rem;
    margin-top: 2%;
  }
  .el-p-uppercase {
    font-size: 0.5rem !important;
  }
  .el-section {
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
  }
  .el-card-container {
    display: flex;
    width: 26%;
    height: auto;
  }
  .el-card-container img {
    width: 90%;
  }
  
  .el-card-container-edit-main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26%;  
    padding-bottom: 2%;
  }
  .el-card-container-edit-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;  
    font-size: 0.6rem;
  }
  .el-card-edit-button {
    margin-top: 3%;
    height: auto;
    width: 30%;
    font-size: 0.6rem;
  }
} 

@media (max-width: 768px) {
  .el-main-container {
    height: auto; 
  }
  .el-main-container img{
    width: 60%;
  }
  .el-header {
    height: auto;
  }
  .el-description p {
    width: 80%;
    font-size: 0.7rem;
    margin-top: 2%;
  }
  .el-p-uppercase {
    font-size: 0.5rem !important;
  }
  .el-section {
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
  }
  .el-card-container {
    display: flex;
    width: 26%;
    height: auto;
  }
  .el-card-container img {
    
    width: 90%;
    
  }
  
  .el-card-container-edit-main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26%;  
    padding-bottom: 2%;
  }
  .el-card-container-edit-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;  
    font-size: 0.6rem;
  }
  .el-card-edit-button {
    margin-top: 3%;
    height: auto;
    width: 30%;
    font-size: 0.6rem;
  }
}

@media (max-width: 667px) {
  .el-main-container {
    height: auto; 
  }
  .el-main-container img{
    width: 60%;
  }
  .el-header {
    height: auto;
  }
  .el-description p {
    width: 80%;
    font-size: 0.7rem;
    margin-top: 2%;
  }
  .el-p-uppercase {
    font-size: 0.5rem !important;
  }
  .el-section {
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
  }
  .el-card-container {
    display: flex;
    width: 26%;
    height: auto;
  }
  .el-card-container img {
    width: 90%;
  }
  
  .el-card-container-edit-main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26%;  
    padding-bottom: 2%;
  }
  .el-card-container-edit-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;  
    font-size: 0.6rem;
  }
  .el-card-edit-button {
    margin-top: 3%;
    height: auto;
    width: auto;
    font-size: 0.6rem;
  }
}  

@media (max-width: 425px) {
  .el-main-container {
    height: auto; 
  }
  .el-main-container img{
    width: 100%;
  }
  .el-header {
    height: auto;
  }
  .el-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    row-gap: 4%;
  }
  .el-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .el-description p {
    width: 70%;
    font-size: 1.0rem;
    margin-top: 2%;
  }
  .el-p-uppercase {
    font-size: 0.8rem !important;
  }
  .el-card-container {
    margin-top: 4%;
    width: 80%;
    height: 75%;
  }
  .el-card-container img {
    width: 80%;
  }
  .el-card-container-edit-main {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: auto;
    width: 80%;  
    height: auto;
    margin-top: 4%;
  }
  .el-card-container-edit-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88%;  
    font-size: 0.9rem;
  }
  .el-card-edit-button {
    height: auto;
    width: 30%;
    font-size: 1rem;
  }
  .el-warning-msg {
    height: 100vh;
  }
  
}

