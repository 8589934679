body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}

.a-loading {
  border: 6px solid #e5e5e5;
  border-top-color: #51d4db;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  animation: is-rotating 1s infinite;
}
