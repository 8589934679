.section-dsword {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;
  background-color: #1b63f9;
}

.card-dsword {
  background-color: white;
  width: 95%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
  border-radius: 8px;
}

.item-dsword {
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
  margin: 10px 0;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
}

.item-date-dsword {
  border-right: 1px solid gray;
  padding-right: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

.item-body-dsword {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 10px;
}

.a-button-pages {
  background-color: #1cbe1c;
  color: white;
  padding: 10px 15px;
}
